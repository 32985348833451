import { RouteConfig } from '../types/RouteConfig';
import { accountRoutes } from './account/routes';
import { biopakRoutes } from './biopak/routes';
import { bookerRoutes } from './booker/routes';
import { calorRoutes } from './calor/routes';
import { documentsRoutes } from './documents/routes';
import { errorRoutes } from './error/routes';
import {
    digitalSmsRoute, legacyControlPanelRoute, legacyFoodHygieneRoute, legacyForSaleItemsRoute, legacyLocalAuthorityRoute, legacyMyDetailsRoute, legacyMyWorkOppsRoute, legacySendDocumentsRoute,
    legacyTrainingRoute, legacyWorkOppRoute, wordpressContactUsRoute, wordpressMemberResourcesRoute
} from './external-routes';
import { financeRoutes } from './finance/routes';
import { iconsRoute } from './icons/route';
import { landingPageRedirectionRoute } from './landing-page-redirection/route';
import { dashboardPageRoute } from './member-home/route';
import { myAccountRoutes } from './my-account/routes';
import { nisbetsRoutes } from './nisbets/routes';
import { rewardsRoutes } from './rewards/routes';
import { starterPageRoute } from './starter-page/route';
import { testPageRoute } from './test-page/route';
import { unitPremisesRoute } from './unit-premises/route';
import { walletPageRoute } from './wallet/home/route';
import { maintenanceRoute } from '../pages/maintenance/route';
import { helpVideosPageRoute } from './help-videos/route';
import { enterCompanyDetailsRoute } from './enter-company-details/route';
import { myGasEngineerRoute } from '../pages/my-gas-engineer/route';

export const homeRoute = landingPageRedirectionRoute;

const schemeManagementRoutes = [
    bookerRoutes,
    biopakRoutes,
    calorRoutes,
    nisbetsRoutes,
]

const memberRoutes = [
    dashboardPageRoute,
    digitalSmsRoute,
    myAccountRoutes,
    documentsRoutes,  
    unitPremisesRoute,
    legacyControlPanelRoute,
    legacySendDocumentsRoute,
    legacyTrainingRoute,
    legacyWorkOppRoute,
    legacyMyWorkOppsRoute,
    myGasEngineerRoute,

    rewardsRoutes,
    walletPageRoute,

    legacyFoodHygieneRoute,
    legacyLocalAuthorityRoute,

    wordpressMemberResourcesRoute,
    legacyForSaleItemsRoute,
    helpVideosPageRoute,
    wordpressContactUsRoute,
    enterCompanyDetailsRoute

]

const developerRoutes = [
    maintenanceRoute,
    testPageRoute,
    iconsRoute,
]

export const routes: RouteConfig[] = [
    homeRoute,
    starterPageRoute,
    accountRoutes,
    ...memberRoutes,
    ...schemeManagementRoutes,
    financeRoutes,
    ...developerRoutes,
    errorRoutes,
]