import { lazy } from "react";
import { RouteConfig } from "../../types/RouteConfig";

export const enterCompanyDetailsRoute: RouteConfig<'with-page'> = {
    name: 'Company Details',
    path: '/enter-company-details',
    page: lazy(() => import('.')),
    icon: 'mdi mdi-file-document-box-multiple-outline',
    roles: ['ncassMember'],
    flags: ['missing-company-details']
}